.features-split {

	canvas {
		width: 350px !important;
	}

	.section-inner {
		padding-top: $features-split--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $features-split-header--padding__mobile;
	}
}

@include media( '>medium' ) {

	.features-split {

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
		}
	}
}
