.stake-balance-container {
  color: white;
  margin: 10px auto; /* Automatski margini za centriranje */
  width: 100%;
  max-width: 600px;
  text-align: center; /* Centriranje teksta */
}

.stake-title,
.stake-balance,
.stake-verify-link {
  margin-right: 15px; /* Dodajte razmak između elemenata */
  display: inline-block; /* Održava elemente u istoj liniji */
}

.stake-title {
  font-weight: bold; /* Naslov učinite podebljanim */
}

.stake-verify-link {
  text-decoration: none; /* Uklonite podcrtavanje */
  color: #0d6efd; /* Plava boja za link */
  padding: 5px; /* Dodajte malo prostora oko teksta */
  border-radius: 4px; /* Zaobljeni uglovi za dugme */
  background-color: transparent; /* Transparentna pozadina */
}

.stake-verify-link:hover {
  text-decoration: underline; /* Podcrtavanje kada je miš preko linka */
  color: #ffffff; /* Promenite boju teksta prilikom hover-a */
}

.stake-balance-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centriranje sadržaja horizontalno */
}
